.Footer {
  margin: 3vw auto;
}

.OverlayText {
  position: absolute;
  opacity: 0.05;
  font-size: 3.3vw;
  letter-spacing: 0px;
  color: #312e2e;
  text-transform: uppercase;
  font-weight: bold;
}

.OverlayContainer {
  display: flex;
  width: 43.4vw;
  position: relative;
  flex: 1;
  height: 8.17vw;
  align-items: center;
}

.ThankyouText {
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 800;
  font-size: 2vw;
  width: 26.19vw;
  margin: auto;
}

.DonationCard {
  width: 30vw;
  height: 12.18vw;
  box-shadow: 0px 10px 15px #181d9233;
  border: 2px solid #ececec;
  border-radius: 0.57vw;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.FooterContainer {
  grid-gap: 7vw;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 2.5vw;
}

.DonationCard img {
  height: 4vw;
}

.ImagesContainer {
  margin-top: 1.5vw;
  margin-left: 0.5vw;
}

.DonateButton {
  margin-top: 1vw;
  display: flex;
  background: #ffe826;
  cursor: pointer;
  width: 11vw;
  height: 3.7vw;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 0.57vw;
  color: darkgoldenrod;
  font-weight: 500;
  font-size: 1.3vw;
  background-position: center;
  transition: background 0.8s;
}

.DonateButton:hover {
  background: #ffe826 radial-gradient(circle, transparent 1%, #ffe826 1%) center/15000%;
}
.DonateButton:active {
  background-color: #e0d153;
  background-size: 100%;
  transition: background 0s;
}

@media only screen and (max-width: 600px) {
  .DonationCard {
    border-radius: 2vw;
    height: 35vw;
    width: 86.1vw;
  }
  .DonateButton {
    font-size: 3.2vw;
    height: 10.6vw;
    width: 35vw;
  }
  .DonationCard img {
    height: 15vw;
    width: 17vw;
  }
  .FooterContainer {
    height: 75vw;
    grid-template-columns: auto;
  }
  .OverlayContainer {
    width: unset;
  }
  .OverlayText {
    font-size: 6.4vw;
  }
  .ThankyouText {
    width: 58%;
    font-size: 3.6vw;
  }
}
