.SchoolContent {
  margin: 8.5vw 9.2vw;
}

.FormContainer {
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 1vw;
  opacity: 1;
  margin-top: 5vw;
}

.FormContent {
  padding: 4.06vw 6.14vw;
}

.SchoolContent input,
textarea {
  width: 28.34vw;
}

.InputContainer {
  display: grid;
  grid-row-gap: 2.5vw;
}

.Submit {
  margin-left: auto;
  margin-top: 2.5vw;
}

.Container p {
  font-weight: 500;
  font-size: 1.3vw;
}

.DoubleSpace {
  margin-block-start: 3em;
}

.SubHeading {
  font-size: 1.5vw !important;
  font-weight: 500;
}

.GraduateImage {
  height: 28.08vw;
  width: 28.08vw;
}

.MobileImage {
  display: none;
}

@media only screen and (max-width: 600px) {
  .Image {
    display: none;
  }
  .SubHeading {
    font-size: 3.73vw !important;
  }
  .Container p {
    font-size: 3.73vw !important;
  }
  .MobileImage {
    display: block;
  }
  .MobileImage img {
    margin-left: -5vw;
    height: 95.8vw;
    width: 97.8vw;
  }
  .DoubleSpace {
    margin-block-start: 1em;
  }
  .SchoolContent {
    margin: 8.5vw 5vw;
  }
  .FormContent {
    padding: 4.06vw 3.14vw;
  }

  .Inputs {
    flex-direction: column;
    grid-row-gap: 3vw;
  }
  .Addresses {
    grid-row-gap: 3vw;
  }
  .Submit {
    margin: auto;
    margin-top: 2.5vw;
    width: 80%;
    border-radius: 2vw;
  }
}
