.SchoolContent {
  margin: 8.5vw 9.2vw;
}

.FormContainer {
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 1vw;
  opacity: 1;
  margin-top: 5vw;
}

.FormContent {
  padding: 4.06vw 6.14vw;
}

.SchoolContent input,
textarea {
  width: 28.34vw;
}

.InputContainer {
  display: grid;
  grid-row-gap: 2.5vw;
}

.Submit {
  margin-left: auto;
  margin-top: 2.5vw;
}

.Container p {
  font-weight: 500;
  font-size: 1.3vw;
}

.SubHeading {
  font-size: 1.5vw !important;
  font-weight: 500;
}

.FooterTextContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #800e12;
  border-radius: 0.53vw;
  opacity: 1;
  height: 5.5vw;
  margin-top: 3vw;
}

.FooterText {
  height: 80%;
  width: 98.5%;
  background: rgb(255, 29, 37, 0.1);
  box-shadow: 6px 6px 20px #0000000f;
  border-radius: 0.52vw;
  margin: 0.5vw;
  color: #d94d52;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FooterContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 7vw;
}

.ForgetPassword {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .Inputs {
    flex-direction: column;
    grid-row-gap: 3vw;
  }
  .SchoolContent {
    margin: 8.5vw 3.2vw;
  }
  .SchoolContent input,
  textarea {
    width: unset;
  }
  .FooterTextContainer {
    padding: 3vw;
    height: 30vw;
  }
  .FooterText {
    height: 100%;
    text-align: center;
    font-size: 4.5vw;
  }

  .FooterContainer {
    grid-row-gap: 5vw;
    flex-direction: column-reverse;
  }
  .FooterContainer > div:first-child {
    flex-direction: column;
  }
  .Submit {
    margin: auto;
    font-size: 3.2vw;
    border-radius: 2vw;
    height: 12vw;
    width: 95%;
  }
  .Login {
    outline: none;
    border: transparent;
    cursor: pointer;
    margin-top: 1vw;
    color: #ffffff;
    background: #312e2e 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 15px #181d9233;
  }
  .ForgetPassword {
    text-decoration: underline;
    color: #800e12;
    text-transform: capitalize;
    align-self: center !important;
  }
}
