.Content {
  margin: 8.5vw 9.2vw;
}

.Container p {
  font-weight: 500;
  font-size: 1.3vw;
}

.TextContainer {
  border-left: 0.4vw solid #7b0002;
  padding-left: 2vw;
}

.GraduateImage {
  border-radius: 1vw;
  height: 28.08vw;
  width: 28.08vw;
}

.Container > div:nth-child(2) {
  margin-top: 10.625vw;
  grid-column-gap: 2vw;
}

.LeadersContainer {
  margin-top: 7.85vw;
}

.SubText {
  font-size: 1.562vw;
  color: #312e2e;
  font-weight: 500;
  margin-block-start: 0;
}

.CardsContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 4vw;
}

.Footer {
  margin-top: 8vw;
  display: grid;
  grid-template-columns: auto auto;
}

.FooterCard {
  position: relative;
  font-size: 1.2vw;
  color: white;
  width: 31.3vw;
  height: 13.07vw;
  border-radius: 0.52vw;
  background: #7b0002 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
}

.FooterOverlay {
  width: 100%;
  margin: auto;
  text-align: center;
  position: absolute;
  opacity: 0.1;
}

.FooterOverlay img {
  height: 12.65vw;
  width: 12.65vw;
}

.FooterTextContainer {
  padding: 3.02vw;
}

.OverlayText {
  position: absolute;
  opacity: 0.05;
  font-size: 3.3vw;
  letter-spacing: 0px;
  color: #312e2e;
  text-transform: uppercase;
  font-weight: bold;
}

.OverlayContainer {
  align-self: center;
  width: 31.4vw;
  display: flex;
  position: relative;
  flex: 1;
  height: 8.17vw;
  align-items: center;
}

.ThankyouText {
  text-align: center;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 800;
  font-size: 2vw;
  width: 26.19vw;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .Content {
    margin: 8.5vw 6.2vw;
  }

  .GraduateImage {
    height: 93vw;
    width: 93vw;
  }

  .TextContainer {
    border: none;
  }

  .TextContainer p,
  .Container p {
    font-size: 3.2vw;
    line-height: 2;
  }

  .Container > div {
    flex-direction: column;
  }

  .Container > div:last-child img {
    display: none;
  }

  .SubText {
    font-size: 3.2vw;
  }

  .CardsContainer {
    grid-template-columns: auto;
    grid-row-gap: 12vw;
  }

  .FooterCard {
    height: 35vw;
    border-radius: 2.2vw;
    font-size: 3.2vw;
    width: 100%;
  }

  .Footer {
    grid-template-columns: auto;
    grid-row-gap: 15vw;
    margin: 15vw 0;
  }

  .FooterTextContainer {
    padding: 10.02vw;
  }

  .FooterOverlay img {
    height: 34.65vw;
    width: 37.65vw;
  }

  .OverlayContainer {
    width: 100%;
    height: 22.6vw;
  }

  .OverlayText {
    font-size: 9vw;
  }

  .ThankyouText {
    width: 52vw;
    font-size: 5.3vw;
  }
}
