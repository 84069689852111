.Header {
  width: 100%;
  height: 8vh;
  background: #a52038;
  display: flex;
}

.Logo {
  border-radius: 10vw;
  display: flex;
  height: 8vw;
  margin-left: 12.44vw;
  width: 8.6vw;
  z-index: 2;
  position: relative;
}

.Logo > img:last-child {
  position: absolute;
  height: 8.4vw;
}

.Logo > img {
  width: 100%;
}

.LinksContainer {
  height: 100%;
  width: 58%;
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: 1%;
  justify-content: space-between;
  color: white;
  font-size: 0.95vw;
}

.LinksContainer a,
.ProfileLink {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0.05% 1.5%;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.LinksContainer .ActiveLink {
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 0.2vw 0.78vw;
}

.LinksContainer a:last-child {
  padding: 0 0;
  text-align: justify;
}

.LinksContainer .Link:last-child img {
  cursor: pointer;
  width: 1.875vw;
  height: 1.875vw;
  vertical-align: text-bottom;
}

.HamMenu {
  display: none;
}

.Profile {
  visibility: hidden;
  width: 9.3vw;
  background: #ffffff;
  font-size: 1.04vw;
  font-weight: 500;
  padding: 0.94vw 1.25vw;
  border-radius: 0.2vw;
  display: flex;
  position: absolute;
  z-index: 1;
  right: 2vw;
  flex-flow: column;
  grid-row-gap: 0.7vw;
}

.ProfileContainer:hover .Profile {
  visibility: visible;
  z-index: 3;
}

.Profile div,
.Profile a {
  color: #000000;
}

@media only screen and (max-width: 600px) {
  .Header {
    height: 13.6vw;
  }
  .Logo {
    margin-left: 8.8vw;
    width: 18.6vw;
    height: 18.6vw;
    z-index: 2;
    position: relative;
  }
  .LinksContainer {
    display: none;
  }
  .ActiveLink {
    padding: 1vw 12vw;
    outline-offset: 0px;
    outline: none;
    /* display: block;
    border: 1px solid;
    border-radius: 4vw; */
  }
  .HamMenu {
    display: block;
  }
  .Logo > img {
    height: 19.6vw;
  }
  .Logo > img:last-child {
    position: absolute;
    height: 20vw;
  }
  /* .Overlay {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 5;
  } */
}
