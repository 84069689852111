.Welcome {
  height: 92vh;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.Title {
  color: black;
  text-transform: capitalize;
  text-align: left;
  font-size: 3.3vw;
}

.WelcomeContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.Subtitle {
  font-size: 1.2vw;
  width: 37.7vw;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}

.LatestEvents {
  height: 100%;
  display: flex;
  align-items: flex-end;
  background-image: url('../../assets/homeBg.png');
  background-size: contain;
  background-repeat: round;
  position: relative;
}

.LatestEventsOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.4);
}
.WelcomeOverlay {
  display: none;
}

.TextContent {
  z-index: 2;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .Welcome {
    position: relative;
  }
  .WelcomeOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    display: block;
  }
  .LatestEvents {
    background: none;
    width: 100%;
    height: fit-content;
  }
  .LatestEventsOverlay {
    display: none;
  }
  .Welcome {
    display: flex;
    height: 90.8vh;
    flex-flow: column;
    background: url('../../assets/homeBgm.png');
    background-size: contain;
    background-repeat: round;
  }
  .WelcomeContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: unset;
    justify-content: center;
    z-index: 1;
    margin-top: 53vw;
  }
  .Title {
    font-size: 8vw;
    margin-bottom: 6vw;
    color: white;
  }
  .Title span {
    color: white;
  }
  .Subtitle {
    display: none;
  }
  .Particles {
    display: none;
  }
}
