.Modal {
  padding: 4vw 4.375vw;
  width: 41.04vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 12px 12px 60px #0000001a;
  border-radius: 0.5vw;
  opacity: 1;
}

.Title {
  font-size: 1.2vw;
  letter-spacing: 0px;
  color: #800e12;
  opacity: 1;
  font-weight: 500;
}

.SubTitle {
  letter-spacing: 0px;
  color: #3d3d3d;
  text-transform: capitalize;
  opacity: 1;
  font-size: 1.1vw;
  font-weight: 500;
  margin-top: 1.5625vw;
}

.RadioContainer {
  position: relative;
  align-items: center;
  margin-top: 1vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  opacity: 1;
  height: 2.8125vw;
  padding-left: 1vw;
}

.CardContainer {
  margin-top: 0.7vw;
}

.CardNo input {
  height: 2.8vw;
  font-family: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  opacity: 1;
  width: 97%;
  padding-left: 1vw;
  font-size: 1.2vw;
  outline: none;
}

.CardNo ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'poppins';
}

.CardNo {
  position: relative;
}

.CardNo img {
  position: absolute;
  height: 1vw;
  top: 1vw;
  right: 1vw;
}

.CardSub img {
  position: absolute;
  height: 1vw;
  top: 1vw;
  right: 1vw;
}

.CardSub {
  margin-top: 0.7vw;
  display: grid;
  grid-template-columns: auto auto auto;
}

.CardSub input {
  height: 2.8vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  opacity: 1;
  font-size: 1.2vw;
  padding-left: 3vw;
  outline: none;
}

.CardSub > input:first-child {
  width: 15.5vw;
}

.CardSub > input:nth-child(2) {
  width: 9.5vw;
  padding-left: 1vw;
}

.CardSub > div:last-child input {
  width: 9.5vw;
  padding-left: 1vw;
}

.SubmitContainer {
  margin-top: 2vw;
  display: flex;
  justify-content: flex-end;
}

.ImagesContainer {
  position: absolute;
  top: 0.7vw;
  right: 1vw;
}

.ImagesContainer img {
  width: 2vw;
  height: 1vw;
}
