.VolunteerForm {
  margin-top: 3vw;
  padding: 4.06vw 6.14vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 1vw;
  opacity: 1;
}

.FormContainer {
  grid-row-gap: 2.5vw;
  display: grid;
  margin-top: 4.42vw;
}

.FormContainer > div {
  display: flex;
  justify-content: space-between;
}

.Submit {
  margin-left: auto;
  margin-top: 4vw;
}

@media only screen and (max-width: 600px) {
  .FormContainer > div {
    flex-flow: column;
  }
  .FormContainer input {
    width: 73vw !important;
  }
  .PhoneInput input {
    width: 62vw !important;
  }
}
