.Content {
  margin: 5.57vw 6.3vw 5vw 9.01vw;
}

.Content > div {
  margin-top: 1vw;
}

.GraduateImage {
  border-radius: 2vw;
  height: 26.08vw;
  width: 26.08vw;
}

.TextContainer {
  width: 47vw;
  border-left: 0.4vw solid #7b0002;
  padding-left: 2vw;
}

.TextContainer p {
  font-weight: 500;
  font-size: 1.3vw;
}

.ExpandableContainer {
  width: 81.9vw;
  padding: 2vw 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 1vw;
  opacity: 1;
}

.ExpandableContent {
  margin: 3.8vw 4.79vw;
}

.Footer {
  margin-top: 2vw;
  display: flex;
  justify-content: space-between;
}

.FooterButtons {
  display: flex;
  grid-column-gap: 2vw;
}

.Header {
  position: relative;
}

.Header img {
  border-radius: 1vw;
  height: 14.3vw;
  width: 72.3vw;
}

.Header .Card {
  width: 27.1vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 0.5vw;
  position: absolute;
  top: 7vw;
  right: -2.5vw;
}

.Header .CardContent {
  margin: 4.32vw 2vw;
  grid-row-gap: 2vw;
  display: grid;
}

.FieldContainer {
  display: grid;
  grid-template-columns: 4.6vw auto;
  grid-gap: 1vw;
}

.Field {
  color: #800e12;
  font-size: 1.3vw;
  font-weight: 600;
}

.Value {
  font-size: 1.3vw;
  font-weight: 500;
}

.Title {
  font-size: 2.6vw;
  font-weight: bold;
  width: 46vw;
  min-height: 14vw;
}

.EventContents p {
  font-size: 1.3vw;
  font-weight: 500;
  color: #312e2e;
}

.SocialIconsContainer {
  color: white;
  margin-block-start: 1em;
  display: flex;
  justify-content: space-between;
  margin-top: 0.81vw;
  grid-column-gap: 1vw;
}

.SocialIcon {
  display: flex;
  background: #800e12;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  text-align-last: center;
  justify-content: space-around;
  cursor: pointer;
}

.SocialIcon i {
  font-size: 1.2vw;
  height: 90%;
  writing-mode: vertical-lr;
}

.SocialIcons {
  flex: 0.34;
}

.IconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 1vw;
}

.IconName {
  margin-top: 1vw;
  font-size: 1.3vw;
  font-weight: 500;
  color: #312e2e;
}

.FooterRight {
  display: flex;
  grid-column-gap: 3vw;
}

.HeadContainer {
  display: flex;
  justify-content: space-between;
}
.ReadMore {
  box-shadow: none;
}
@media only screen and (max-width: 600px) {
  .Content {
    margin: 5.57vw 7.2vw;
  }
  .TextContainer {
    flex-direction: column;
    display: flex;
    width: unset;
    border-left: none;
    padding-left: 0;
  }
  .TextContainer p {
    line-height: 2;
    font-size: 3.2vw;
  }
  .GraduateImage {
    height: 95vw;
    width: 95vw;
  }
  .ImageContainer {
    margin-left: -7vw;
  }
  .HeadContainer {
    flex-direction: column;
  }
  .ExpandableContainer {
    width: unset;
  }
  .Header img {
    height: 25.6vw;
  }
  .Title {
    width: unset;
    font-size: 4.12vw;
  }
  .Card {
    padding-bottom: 1px;
    font-size: 2.6vw;
    width: 75.2vw;
  }
  .FieldContainer {
    grid-template-columns: 9.6vw auto;
  }
  .Field,
  .Value {
    font-size: unset;
  }
  .Header .Card {
    position: unset;
    padding-bottom: 1px;
    font-size: 2.6vw;
    width: 75.2vw;
  }
  .Footer {
    flex-direction: column;
    margin-top: 3vw;
  }
  .Footer > div:last-child {
    align-self: normal !important;
  }
  .FooterButtons {
    flex-direction: column;
  }
  .FooterButtons div {
    width: 100%;
  }
  .ReadMore {
    width: 99%;
    height: 12vw;
    font-size: 3.2vw;
    border-radius: 2vw;
  }
  .EventContents p {
    font-size: 2.6vw;
    line-height: 1.7;
  }
  .SocialIcon {
    width: 10vw;
    height: 10vw;
  }
  .SocialIcon i {
    font-size: 4.2vw;
  }
  .FooterRight {
    width: 99%;
    height: 12vw;
    font-size: 3.2vw;
    border-radius: 2vw;
    margin-top: 3vw;
    justify-content: space-around;
  }
  .IconName {
    font-size: 3.2vw;
  }
}
