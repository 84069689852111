.Footer {
  height: 30vw;
  background: #232222 0% 0% no-repeat padding-box;
  flex-direction: column;
  display: flex;
}

.FooterContainer {
  width: 70vw;
  display: flex;
  color: white;
  margin: auto 12vw auto auto;
  justify-content: space-between;
}

.FooterContainer ul {
  list-style-type: none;
  padding-inline-start: 0;
}

.FooterContainer li {
  font-size: 0.8vw;
  margin-top: 1vh;
  cursor: pointer;
}

.FooterContainer li > img {
  height: 0.65vw;
  width: 0.75vw;
  padding: 0 0.8vw 0 0;
}

.FooterHeading {
  font-size: 1.05vw;
}

.CalendarContainer {
  margin-block-start: 1em;
}

.CalendarBottomText {
  font-size: 0.7vw;
  margin-top: 1em;
}

.CalendarBottomText span {
  text-decoration: underline;
  cursor: pointer;
}

.SocialIconsContainer {
  margin-block-start: 1em;
  display: flex;
  grid-column-gap: 1vw;
  /* justify-content: space-between; */
}

.SocialIcon {
  display: flex;
  background: #800e12;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  text-align-last: center;
  justify-content: space-around;
  cursor: pointer;
}

.SocialIcon i {
  font-size: 1.2vw;
  height: 90%;
  writing-mode: vertical-lr;
}

.SocialIcons {
  flex: 0.34;
}

.FooterDiv:last-child img {
  height: 3vw;
  width: 3vw;
  cursor: pointer;
}

.Copyrights {
  font-weight: 200;
  font-size: 1.05vw;
  color: white;
  margin: 1vw auto;
}

.Copyrights .Strong {
  font-weight: 500;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .Footer {
    height: unset;
  }
  .FooterContainer {
    width: 100%;
    flex-flow: column;
  }
  .FooterDiv {
    margin: auto;
    margin-top: 11vw;
  }
  .FooterHeading {
    text-align: center;
    font-size: 5.33vw;
  }
  .FooterContainer li {
    font-size: 3.2vw;
    margin-top: 2.5vh;
  }
  .FooterContainer li > img {
    height: 2.65vw;
    width: 2.75vw;
  }
  .CalendarBottomText {
    font-size: 3.7vw;
    margin: 1em;
  }
  .SocialIconsContainer {
    justify-content: center;
  }
  .SocialIcon {
    width: 10vw;
    height: 10vw;
  }
  .SocialIcon i {
    font-size: 4.2vw;
  }
  .Copyrights {
    font-size: 2.66vw;
    margin-top: 9vw;
  }
  .FooterContainer > div:nth-child(4) {
    display: none;
  }
}
