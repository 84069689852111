.LoginModal {
  width: 38vw;
  height: 25vw;
  border-radius: 0.78vw;
  background: white;
  padding: 3.69vw 7.39vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginModal img {
  height: 9vw;
  width: 9vw;
}

.ModalTitle {
  padding-top: 2.34vw;
  font-size: 1.32vw;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  font-weight: bold;
}

.ModalSubTitle {
  padding-top: 1vw;
  font-size: 1.32vw;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  font-weight: 500;
}

.LoginButton {
  margin-top: 2.2vw;
}

@media only screen and (max-width: 600px) {
  .LoginModal {
    width: 73.65vw;
    height: 106.25vw;
  }
  .ModalTitle {
    padding-top: 12.47vw;
    font-size: 4.7vw;
  }
  .ModalSubTitle {
    padding-top: 5vw;
    font-size: 2.82vw;
    line-height: 2;
    color: #312e2e;
  }
  .LoginModal img {
    height: 21.4vw;
    width: 21.4vw;
  }
  .LoginButton {
    margin-top: 11vw;
  }
}
