.DonationModal {
  background: #fff;
  padding: 4vw;
}

.DonationModal input {
  margin-top: 0.5vw;
  font-size: 1vw;
  width: 26.34vw;
  height: 3.8vw;
}

.FormContainer {
  grid-row-gap: 1vw;
  display: grid;
}

.ButtonContainer {
  display: flex;
  grid-column-gap: 1vw;
  margin-left: auto;
}

.ModalContent {
  background: white;
  width: 69vw;
  padding: 2vw;
}

.FormContainer textarea {
  width: 26.34vw;
  height: 8vw;
}

@media only screen and (max-width: 600px) {
  .DonationModal input {
    margin-top: 0.5vw;
    font-size: 3vw;
    height: 7.8vw;
  }
}
