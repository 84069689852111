.AnnouncementsContainer {
  margin: 0 10vw;
}

.WelcomeContainer {
  background-image: url('../../assets/homeBg.png');
  opacity: 0.9999;
  background-repeat: round;
  height: 92vh;
}

.WelcomeOverlay:after {
  position: absolute;
  content: ' ';
  top: 8vh;
  left: 0;
  width: 100%;
  height: 92vh;
  z-index: -1;
  background: black 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.UpcomingEvents {
  margin: 0 10vw;
}

@media only screen and (max-width: 600px) {
  .WelcomeContainer {
    height: 90.8vh;
    opacity: 0.999;
  }
  .WelcomeOverlay:after {
    top: 13.36vw;
    height: 90.8vh;
  }
  .AnnouncementsContainer {
    margin: 0;
  }
  .UpcomingEvents {
    margin: 0 8vw;
  }
}
