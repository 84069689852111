.Card {
  width: 22.6vw;
  height: 28.38vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 1.35vw;
}

.Card img {
  margin-top: 1.875vw;
  width: 18.85vw;
  height: 18.85vw;
  border-radius: 1.354vw;
}

.CardTitle {
  margin: auto;
  font-size: 1.5625vw;
  font-weight: bold;
  color: #800e12;
  text-align: center;
}

.SubTitle {
  font-size: 1vw;
  color: black;
  margin: auto;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .Card {
    width: 86.1vw;
    height: 101.1vw;
    border-radius: 7vw;
  }
  .Card img {
    margin-top: 3.875vw;
    height: 71.7vw;
    width: 71.7vw;
    border-radius: 7vw;
  }
  .CardTitle {
    font-size: 5.8vw;
  }
  .SubTitle {
    font-size: 3.7vw;
  }
}
