.TransactionsTable {
  font-size: 1.02vw;
  font-weight: 500;
}

.TransactionsTable table {
  width: 100%;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  opacity: 1;
  padding: 0.51vw 0px;
}

.TransactionsTable tr {
  padding: 0.3vw 0;
}

.TransactionsTable th {
  font-size: 1vw;
  color: #3d3d3d;
  text-transform: capitalize;
  font-weight: 600;
}

.TransactionsTable td {
  letter-spacing: 0px;
  color: #312e2e;
  text-transform: capitalize;
  opacity: 0.5;
  padding: 0.3vw 0;
}

.TableHead {
  border-bottom: 1px solid #eeeeee;
}

.Filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Filters input {
  margin-top: 0.8vw;
  font-size: 0.95vw;
  width: 8.34vw;
  height: 2.4vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  padding-left: 1.3vw;
}

.Pickers {
  display: flex;
  grid-column-gap: 1vw;
}

.Pickers label {
  margin-right: 0.4vw;
  font-size: 1vw;
}

.filter-button {
  width: 5.5vw;
  height: 2vw;
}

@media only screen and (max-width: 600px) {
  .TransactionsTable {
    font-size: 3.25vw;
    font-weight: 500;
    margin-top: 4vw;
  }
  .TransactionsTable th {
    font-size: 3vw;
  }
  .TransactionsTable table {
    font-size: 2.5vw;
  }
  .Filters {
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 3vw;
  }
  .Pickers {
    align-items: center;
  }
  .Pickers label {
    font-size: 3.25vw;
  }
  .Filters input {
    margin-top: 0.8vw;
    font-size: 0.95vw;
    width: 15.34vw;
    height: 7.4vw;
    border-radius: 0.5vw;
    padding-left: 4.3vw;
  }
}
