.InputContainer {
  display: grid;
  grid-row-gap: 2.5vw;
}

.CardImage {
  width: 18.8vw;
  background: #800e12 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.UItitle {
  font-size: 0.52vw;
  letter-spacing: 0px;
  color: #f3f8fe;
  opacity: 0.5;
}

.UIContent {
  margin-top: 0.2vw;
  font-size: 1vw;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.CardTop {
  margin: 1vw 2vw 0vw 1vw;
  width: 80%;
  display: flex;
  justify-content: space-between;
  top: 0;
  align-items: center;
}

.CardTop > div:first-child {
  color: white;
  font-weight: bold;
  font-style: italic;
  font-size: 1vw;
}

.CVV {
  line-height: 1.2;
  display: grid;
}

.CardNum {
  text-align: center;
  font-size: 1.3vw;
  letter-spacing: 0.2vw;
  color: #ffffff;
  opacity: 1;
  top: 4vw;
  left: 2vw;
  margin-right: 1.3vw;
}

.CardFooter {
  margin: 1vw 4vw 0vw 1vw;
  display: flex;
  justify-content: space-between;
  bottom: 20px;
  width: 80%;
}

.AddCard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.8vw;
  width: 19.6vw;
  height: 9.84vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 12px 12px 60px #0000001a;
  border-radius: 10px;
}

.AddCardText {
  font-size: 1vw;
  letter-spacing: 0px;
  color: #312e2e;
  opacity: 0.4;
}

.CardContainer > div:first-child {
  justify-content: unset;
  grid-column-gap: 3vw;
  align-items: center;
}

.CardParent {
  position: absolute;
  top: -0.5vw;
  width: -webkit-fill-available;
  height: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.WithCross {
  display: flex;
  grid-column-gap: 1.5vw;
}

.CrossIcon {
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  height: 1vw;
  width: 1vw;
  justify-content: center;
  color: #800e12;
  font-size: 1.1vw;
}

@media only screen and (max-width: 850px) {
  .CrossIcon {
    margin-right: 1vw;
  }

  .CardImage {
    width: 60.8vw;
  }
  .CardNum {
    font-size: 4vw;
    margin-right: 7vw;
    margin-top: 9vw;
  }
  .CardTop > div:first-child {
    font-size: 4.25vw;
  }
  .UItitle {
    font-size: 2vw;
  }
  .UIContent {
    font-size: 4.25vw;
  }
  .WithCross {
    grid-column-gap: 3.5vw;
  }
  .CrossIcon {
    height: 4vw;
    width: 4vw;
    font-size: 4.1vw;
    margin-right: -3vw;
  }
  .AddCard {
    height: 29vw;
    width: 60.8vw;
    cursor: pointer;
    margin-left: 7vw;
  }
  .AddCardText {
    font-size: 4.25vw;
  }
}
