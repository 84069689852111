.Content {
  margin: 7vw 9vw;
}

.Content p {
  font-size: 1.3vw;
  font-weight: 500;
}

.Title {
  text-decoration: underline;
  font-size: 1.5625vw;
  letter-spacing: 0px;
  color: #800e12;
  opacity: 1;
  font-weight: 500;
}

.FellowshipRequirements {
  margin: 3vw 0;
}

.ReqCard {
  height: 16.45vw;
  grid-column-gap: 3vw;
  display: grid;
  grid-template-columns: 11vw 67vw;
}

/* .BigTitle {
  margin: auto;
  width: max-content;
  font-size: 11.04vw;
  font-weight: 800;
  color: white;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #800e12;
  text-shadow: 9px -9px #f2e6e7, -1px 2px;
} */

.BigTitle {
  margin: unset;
  color: #800e12;
  width: max-content;
  font-weight: 800;
  font-size: 11vw;
}

.Subtitle {
  margin: auto auto auto 0;
  font-weight: 500;
  font-size: 1.3vw;
}

.ReadMore {
  margin: auto;
}

/* .ReqCard:nth-child(1) {
  margin-left: 5vw;
}
.ReqCard:nth-child(2) {
  margin-left: 17vw;
}
.ReqCard:nth-child(3) {
  margin-left: 30vw;
} */

.mediumMargin {
  margin: 3vw auto;
}

.ResumeBox {
  margin-top: 6vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 1vw;
  padding-bottom: 1vw;
}

.GraduateImage {
  height: 28.08vw;
  width: 28.08vw;
}

.ResumeContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 2.5vw;
}

.Property {
  font-weight: 600;
  color: #800e12;
  text-transform: capitalize;
  font-size: 1.13vw;
}

.Value {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1vw;
  color: #312e2e;
}

.Fields {
  display: grid;
  grid-row-gap: 1.5vw;
}

.FieldContainer {
  display: grid;
  grid-template-columns: 19.45vw 29.1vw;
}

.SContainer {
  display: flex;
  grid-column-gap: 2.3vw;
  margin-top: 2.9vw;
}

.SNo {
  font-size: 2.2vw;
  color: #800e12;
  font-weight: 500;
}

.SContent {
  font-size: 1vw;
  color: #312e2e;
  text-transform: capitalize;
  font-weight: 500;
}

.FooterText {
  margin: 3vw auto;
  width: max-content;
  color: #800e12;
}
.ExpandMobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .Content p {
    font-size: 3.2vw;
    line-height: 2;
  }
  .Title {
    font-size: 4.2vw;
  }
  .Subtitle {
    font-size: 3.2vw;
    width: unset;
  }
  .ReqCard {
    width: unset;
    height: unset;
    flex-flow: column;
    align-items: flex-start;
    display: flex;
  }
  .BigTitle {
    margin: unset;
    font-size: 27.04vw;
  }
  .BigTitle {
    width: max-content;
    font-size: 11.04vw;
    color: white;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #800e12;
    text-shadow: 9px -9px #f2e6e7, -1px 2px;
  }
  .Property,
  .Value {
    font-size: 3.2vw;
  }
  .ResumeBox {
    height: unset;
    padding-right: 4vw;
    border-radius: 4vw;
    padding-bottom: 5vw;
  }
  .ResumeContainer {
    display: flex;
    flex-flow: column-reverse;
  }
  .Fields {
    grid-row-gap: 5.5vw;
  }
  .FieldContainer {
    grid-template-columns: 31vw auto;
  }
  .SContainer {
    display: grid;
    grid-template-columns: 21vw auto;
  }
  .SNo {
    font-size: 15vw;
  }
  .SContent {
    font-size: 3.2vw;
    line-height: 1.5;
  }
  .FooterText {
    width: 81vw;
    margin: inherit;
    margin-left: auto;
    margin-right: auto;
  }
  .GraduateImage {
    height: 85vw;
    width: 85vw;
  }
  .ResumeContainer > div:last-child {
    margin-left: -7vw;
  }
  .ReadMore {
    display: none;
  }
  .Expand {
    display: none;
  }
  .ExpandMobile {
    margin-top: 10vw;
    grid-row-gap: 3vw;
    display: grid;
  }
}
