.Title {
  font-size: 1.3vw;
  color: #ffffff;
  font-weight: 500;
}

.EventsContainer {
  margin-top: 30%;
  width: -webkit-fill-available;
  padding: 2vw;
  z-index: 1;
}

.Card {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.61vw;
  width: 21.5vw;
  height: 11vw;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
}

.CardContent {
  display: flex;
  padding: 1vw;
  justify-content: space-between;
  display: flex;
}

.TextContainer {
  width: 14vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TextMain {
  margin-block-start: 0.4vw;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 1vw;
  font-weight: 500;
}

.TextSub {
  font-size: 0.78vw;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.DateContainer {
  display: flex;
  flex-direction: column;
  width: 4.42vw;
  height: 7.81vw;
  border-radius: 0.78vw;
  background: white;
  color: #800e12;
  align-items: center;
  justify-content: center;
}

.Month {
  font-size: 1.04vw;
}
.Day {
  line-height: 0.9;
  font-size: 1.71vw;
  font-weight: bold;
}

.Cards {
  display: flex;
  justify-content: space-around;
  grid-column-gap: 1vw;
}

@media only screen and (max-width: 600px) {
  .Cards {
    width: unset;
  }
  .EventsContainer {
    margin-top: 0;
    margin-left: 10%;
    padding: 0;
  }
  .Title {
    font-size: 4.26vw;
  }
  .Card {
    padding: 4vw 14px;
    border-radius: 2.2vw;
    height: max-content;
    width: 64.8vw;
  }
  .TextContainer {
    width: 40.5vw;
  }
  .TextMain {
    font-size: 3vw;
  }
  .TextSub {
    font-size: 2.7vw;
  }
  .DateContainer {
    height: 26.4vw;
    width: 15vw;
    border-radius: 3vw;
  }
  .Month {
    font-size: 3.5vw;
  }
  .Day {
    font-size: 5.8vw;
  }
}
