.Login {
  background: url('../../assets/homeBg.svg');
  height: 92vh;
  background-size: cover;
  display: flex;
  color: white;
}

.LoginOverlay::after {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  top: 8vh;
  width: 100%;
  height: 92vh;
  z-index: 1;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.3;
}

.LoginContainer {
  border-radius: 0.7vw;
  width: 37.1vw;
  padding-bottom: 1vw;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.Title {
  margin-top: 2.2vw;
}

.Title p {
  font-size: 4.1vw;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: bold;
  line-height: 1;
  color: white;
}

.Login input {
  width: 26.34vw;
}

.Fields {
  width: fit-content;
  margin: auto;
  margin-top: 1.7vw;
}

.Footer {
  font-size: 1vw;
  display: flex;
  justify-content: space-between;
  margin-top: 2.3vw;
  margin: auto;
}

.FormContainer {
  margin: auto;
  width: 29.9vw;
}

.FormContainer form {
  grid-row-gap: 0.6vw;
  display: grid;
}

.FormContainer > div {
  margin-top: 1vw;
}

.Login input[type='checkbox'] {
  width: unset;
  outline: none;
  border: none;
}

.Login input[type='checkbox']:hover {
  width: unset;
  outline: none;
  border: none;
}

.CheckboxContainer {
  display: flex;
  align-items: center;
}

.FooterButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 0.5vw;
}

.Submit {
  background: white;
  color: #800e12;
}

.Submit:hover {
  background: white radial-gradient(circle, transparent 1%, white 1%) center/15000%;
  color: #800e12;
}

.Submit:active {
  background-color: #ebe4e4;
  background-size: 100%;
  transition: background 0s;
}

.ForgetPassword button {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .Login {
    background: url('../../assets/homeBgm.png');
    background-size: cover;
    height: 94vh;
  }
  .LoginContainer {
    border-radius: 3vw;
    width: 93.6vw;
    padding: 7vw 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .LoginOverlay::after {
    top: 5.8vh;
    height: 94vh;
  }
  .Title p {
    font-size: 8vw;
  }
  .Fields {
    width: 100%;
  }
  .FormContainer {
    grid-row-gap: 5vw;
    display: flex;
    width: max-content;
    flex-flow: column;
  }
  .Footer {
    margin: unset;
    font-size: 3.2vw;
  }
  .FooterButtons {
    grid-row-gap: 3vw;
    flex-direction: column;
  }
  .FooterButtons button {
    width: 100%;
  }
  .Login input {
    margin-top: 3vw;
    border-radius: 2vw;
  }
  .CheckboxContainer label {
    margin-top: 2vw;
  }
  .Footer > a {
    align-self: flex-end;
  }
  .LoginOverlay::after {
    opacity: 0.4;
  }
  .ForgetPassword button {
    margin-top: 3vw;
  }
  .Login .FormContainer {
    display: block;
    margin-top: 2vw;
  }
  .FooterButtons {
    margin-top: 2vw !important;
  }
  .FooterButtons a {
    width: 100%;
  }
  .FooterButtons div:last-child a {
    box-shadow: 0px 10px 15px #181d9233;
    border: 2px solid #ffffff;
    border-radius: 1.2vw;
    color: white;
    background: transparent;
  }
}
