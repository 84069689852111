.Label {
  font-size: 1.02vw;
  font-weight: 500;
}

.InputContainer input {
  margin-top: 1vw;
  font-size: 0.95vw;
  width: 32.34vw;
  height: 2.8vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  padding-left: 3.3vw;
}

.CountryCode {
  position: absolute;
  margin-top: 1.5vw;
  left: 0.5vw;
  z-index: 1;
  font-size: 1vw;
  width: 2.86vw;
  height: 2.23vw;
  background: #800e12 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border-radius: 0.52vw;
  opacity: 1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PhoneInput {
  cursor: pointer;
  position: relative;
}

.PhoneInput input {
  width: 32vw;
  padding-left: 3.6vw;
}

.TextArea > textarea {
  padding-top: 1.2vw;
  margin-top: 1vw;
  font-size: 0.95vw;
  width: 28.34vw;
  height: 10vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  padding-left: 3.3vw;
  resize: none;
}

.PasswordContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.PasswordContainer i {
  font-size: 1vw;
  position: absolute;
  right: 0.5vw;
  margin-top: 0.8vw;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .Label {
    font-size: 3.2vw;
  }
  .InputContainer input {
    height: 11vw;
    width: 80vw !important;
    font-size: 2.8vw;
  }
  .TextArea > textarea {
    width: 80vw !important;
    height: 25vw;
  }
  .PhoneInput input {
    width: 69vw !important;
    padding-left: 14vw;
  }
  .CountryCode {
    border-radius: 2.13vw;
    height: 8.8vw;
    width: 11.2vw;
    font-size: 3.5vw;
    top: 1vw;
    left: 1vw;
  }
  .PasswordContainer i {
    right: 5.5vw;
    font-size: 4vw !important;
  }
}
