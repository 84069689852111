.DonationModal {
  background: #fff;
  padding: 4vw;
}

.DonationModal input {
  margin-top: 0.5vw;
  font-size: 1vw;
  width: 26.34vw;
  height: 3.8vw;
}

.FormContainer {
  grid-row-gap: 1vw;
  display: grid;
}

.ButtonContainer {
  display: flex;
  grid-column-gap: 1vw;
  margin-left: auto;
}

.ModalContent {
  background: white;
  width: 69vw;
  padding: 2vw;
}

.Strength {
  margin-top: 1vw;
  align-self: flex-end;
  width: 100%;
  height: 2.8vw;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  font-size: 0.9vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.strong {
  background: #ade98b;
  color: #4a7e2c;
}

.medium {
  color: #ad5a02;
  background: #fceea4;
}

.weak {
  background: #fc5157 !important;
  color: #730206 !important;
}

@media only screen and (max-width: 600px) {
  .DonationModal input {
    margin-top: 0.5vw;
    font-size: 3.25vw;
    height: 9.8vw;
    width: 75vw !important;
  }
}
