.AnnouncementCard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10.5vw 4vw;
}

.AnnouncementCard .CardContent {
  flex: 1;
}

.AnnouncementCard .AnnouncementImage {
  flex: 0.85;
  text-align: center;
}

.AnnouncementCard .CardContent p {
  letter-spacing: 0px;
  color: #312e2e;
  opacity: 1;
  font-size: 1.1vw;
  font-weight: 500;
}

.AnnouncementCard .AnnouncementImage img {
  margin-top: 4.5vw;
  height: 24vw;
  width: 24vw;
}

.AnnouncementCard:nth-child(even) {
  flex-direction: row-reverse;
  flex: 0.9;
}

.AnnouncementCard:nth-child(even) .content {
  width: 84%;
}

.AnnouncementCard:nth-child(even) .AnnouncementImage {
  flex: 0.55;
  text-align: initial;
}

.AnnouncementCard:nth-child(3) .AnnouncementImage img {
  height: 19vw;
  box-shadow: 0px 20px 30px #800e124d;
  border-radius: 10px;
  opacity: 1;
  background: #7c0002;
  width: 19vw;
  margin-top: 2vw;
}

.AnnouncementCard:nth-child(4) .AnnouncementImage img {
  height: 23vw;
  opacity: 1;
  width: 23vw;
  margin-top: 4vw;
}

@media only screen and (max-width: 600px) {
  .AnnouncementCard {
    flex-flow: column-reverse;
    width: 90%;
  }
  .AnnouncementCard .AnnouncementImage {
    flex: unset;
  }
  .AnnouncementCard .AnnouncementImage img {
    height: 95vw;
    width: 95vw;
  }
  .AnnouncementCard:nth-child(even) {
    flex-direction: column-reverse;
    flex: unset;
  }
  .AnnouncementCard .CardContent p {
    font-size: 3.2vw;
  }
  .AnnouncementCard .CardContent {
    margin-left: 6vw;
  }
  .AnnouncementCard:nth-child(3) .AnnouncementImage img {
    height: 80vw;
    width: 80vw;
  }
  .AnnouncementCard:nth-child(3) .CardContent {
    margin-top: 6vw;
  }
  .AnnouncementCard:nth-child(4) .AnnouncementImage img {
    height: 95vw;
    width: 93vw;
  }
}
