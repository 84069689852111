.Membership {
  padding-right: 4.42vw;
}
.Inactive {
  color: #800e12;
  text-transform: capitalize;
}

.MembershipContent {
  font-size: 1vw;
  color: black;
  display: flex;
  height: 3.5vw;
  background: rgba(255, 255, 255, 0.834);
  box-shadow: 0px 10px 15px #800e1233;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0vw 1vw;
}

@media only screen and (max-width: 600px) {
  .Membership {
    padding-top: 7vw;
    padding-right: 6.1vw;
  }
  .MembershipContent {
    margin-top: 2vw;
    font-size: 3.2vw;
    height: 11.7vw;
    border-radius: 1vw;
    padding: 0vw 2vw;
  }
}
