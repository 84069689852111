.Content {
  margin: 5.18vw 9vw;
}

.SubFont {
  font-size: 1.3vw;
  line-height: 2.2vw;
}

.PaypalCard {
  box-shadow: 0px 10px 15px hsla(238, 72%, 33%, 0.2);
  border: 2px solid hsla(0, 0%, 93%, 1);
  border-radius: 1.2vw;
  width: 41.09vw;
  height: 12.6vw;
  padding: 1.56vw;
}

.HeadContainer {
  display: flex;
  justify-content: space-between;
}

.JoinUsContainer {
  width: 32.44vw;
  height: 12.6vw;
  justify-content: space-around;
  display: flex;
  flex-flow: column;
}

.PaypalTitle {
  font-size: 1.56vw;
  font-weight: 500;
}

.SubText {
  font-size: 1.3vw;
  margin-top: 1.8vw;
  display: flex;
  align-items: center;
}

.PaypalLogo {
  width: 10.8vw;
  height: 4vw;
}

.MembershipContainer {
  margin-top: 5vw;
  display: grid;
}

.MembershipCardContainer {
  margin-top: 5vw;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 4vw;
}

.Card {
  width: 22.6vw;
  height: 22.38vw;
  background: hsla(0, 0%, 100%, 1) 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px hsla(0, 0%, 0%, 0.07);
  border: 3px solid hsla(0, 0%, 96%, 1);
  border-radius: 1vw;
  padding: 4.06vw 1.87vw;
}

.Card:hover {
  background: hsla(358, 80%, 28%, 1) 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.Card:hover .SubTitle,
.Card:hover .CardTextContent {
  color: white;
}

.Card:hover .BigTitle {
  -webkit-text-stroke-color: #800e12;
  text-shadow: 1px 1px white, 2px 4px white;
}

.BigTitle {
  margin: auto;
  width: max-content;
  font-size: 6vw;
  font-weight: 800;
  color: white;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-shadow: 1px 1px black, 2px 4px #000000;
}

.SubTitle {
  font-weight: bold;
  font-size: 2.18vw;
  color: hsla(358, 80%, 28%, 1);
  width: max-content;
  margin: auto;
}

.CardTextContent {
  font-size: 1vw;
  color: hsla(0, 0%, 0%, 1);
  width: 18.8vw;
  margin: auto;
}

.MembershipText {
  background: hsla(359, 100%, 24%, 1) 0% 0% no-repeat padding-box;
  height: 13.07vw;
  box-shadow: 30px 30px 50px hsla(0, 0%, 0%, 0.07);
  border-radius: 0.5vw;
  font-size: 1.23vw;
  color: white;
  margin-top: 6vw;
}

.MembershipText p {
  padding: 3.54vw 4.34vw;
}

.Eligibility {
  margin-top: 6vw;
}

.BorderContainer {
  margin: 5vw 0;
}

.BookTitle {
  text-decoration: underline;
  letter-spacing: 0px;
  color: hsla(358, 80%, 28%, 1);
  font-size: 1.5vw;
  font-weight: 500;
}

.BookAwardFund {
  margin-top: 2.4vw;
  display: flex;
  grid-column-gap: 2.75vw;
}

.BookAwardFund p {
  font-size: 1.3vw;
  font-weight: 500;
}

.WhiteWall {
  width: 26.6vw;
  height: 26.6vw;
}

.ImageContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.OverlayText {
  position: absolute;
  text-align: center;
  letter-spacing: 0px;
  color: hsla(358, 80%, 28%, 1);
  opacity: 1;
  font-size: 1.21vw;
  font-weight: bold;
}

.Footer {
  margin-top: 7vw;
}

.FooterTitle {
  font-size: 1.56vw;
  font-weight: 600;
}

.PaymentMethod {
  margin: 7vw 0;
}

.PaymentMethod p {
  font-size: 1.3vw;
  font-weight: 500;
}

.ModalContent {
  background: white;
  width: 69vw;
  padding: 2vw;
}

.SummaryModalContent {
  background: white;
  padding: 2vw;
}

@media only screen and (max-width: 600px) {
  .Content {
    color: #312e2e;
  }
  .SubFont {
    line-height: 2;
    font-size: 3vw;
    margin: 4vw 0vw;
  }
  .PaypalCard {
    justify-content: space-evenly;
    width: 80.3vw;
    height: 43.5vw;
    display: flex;
    flex-flow: column;
    color: black;
  }
  .PaypalTitle {
    font-size: 3.73vw;
  }
  .SubText {
    grid-row-gap: 5vw;
    align-items: start;
    flex-flow: column;
    font-size: 3.2vw;
  }
  .JoinUsContainer {
    height: unset;
    flex-direction: column;
    width: unset;
  }
  .HeadContainer {
    flex-direction: column;
  }
  .PaypalLogo {
    width: 36.8vw;
    height: 14vw;
  }
  .CardTextContent {
    font-size: 3.73vw;
    width: 85%;
    text-align: center;
  }
  .Card {
    height: 100vw;
    width: 80.3vw;
    align-items: center;
    display: flex;
    border-radius: 3vw;
  }
  .BigTitle {
    font-size: 22.6vw;
  }
  .SubTitle {
    font-size: 8.2vw;
  }
  .MembershipCardContainer {
    grid-template-columns: unset;
    color: black;
  }
  .CardContent {
    width: 100%;
  }
  .MembershipText {
    display: flex;
    font-size: 3.2vw;
    height: 75.4vw;
    border-radius: 3vw;
    align-items: center;
    line-height: 2.3;
  }
  .Eligibility p {
    line-height: 2.3;
  }
  .BookAwardFund {
    flex-direction: column-reverse;
  }
  .BookTitle {
    font-size: 4vw;
  }
  .BookAwardFund p {
    line-height: 2.1;
    font-size: 3.2vw;
  }
  .WhiteWall {
    height: 85.8vw;
    width: 85.8vw;
  }
  .OverlayText {
    font-size: 4vw;
  }
  .FooterTitle {
    font-size: 4vw;
  }
  .PaymentMethod p {
    font-size: 3.2vw;
    line-height: 2;
  }
}
