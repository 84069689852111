.Profile {
  margin: 1.45vw 9.01vw 6.7vw 9.01vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 1vw;
}

.Content {
  padding: 4.16vw 6.14vw;
}

.InputContainer {
  display: grid;
  grid-row-gap: 2.5vw;
}

.Content input,
textarea {
  width: 28.34vw;
}

.Strength {
  align-self: flex-end;
  width: 100%;
  height: 2.8vw;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  font-size: 0.9vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.strong {
  background: #ade98b;
  color: #4a7e2c;
}

.medium {
  color: #ad5a02;
  background: #fceea4;
}

.weak {
  background: #fc5157 !important;
  color: #730206 !important;
}

.CheckboxContainer {
  margin-top: 3vw;
  width: 31.64vw;
  display: flex;
  align-items: center;
}

.Profile input[type='checkbox'] {
  width: unset !important;
  outline: none;
  border: none;
}

.Profile input[type='checkbox']:hover {
  width: unset !important;
  outline: none;
  border: none;
}

.SubscribeText {
  cursor: pointer;
  color: red;
  font-size: 0.8vw;
}

.MembershipContainer {
  background: #800e12 0% 0% no-repeat padding-box;
  width: max-content;
  border-radius: 1vw;
  padding: 1vw;
  color: white;
}

.MembershipTag {
  display: flex;
  grid-column-gap: 3vw;
  align-items: center;
  justify-content: space-between;
}

.MembershipContainer .Title {
  font-weight: 500;
  font-size: 1.27vw;
}

.MembershipContainer .Subtitle {
  font-weight: 500;
  font-size: 1.27vw;
  margin-top: 2vw;
}

.MembershipContainer .Button {
  background: white;
  border-radius: 0.31vw;
  height: 2vw;
  color: black;
  display: flex;
  align-items: center;
  font-size: 1vw;
  padding: 0 1vw;
  cursor: pointer;
}

.ButtonPrimary {
  width: 12vw;
  padding: 0vw 1vw;
  margin-left: auto;
  margin-bottom: -1vw;
}

.ModalContent {
  background: white;
  width: 69vw;
  padding: 2vw;
}

.Submit {
  margin-top: 2vw;
}

@media only screen and (max-width: 600px) {
  .MobileInputs {
    flex-direction: column;
    grid-row-gap: 2vw;
  }
  .ButtonPrimary {
    width: unset;
    padding: 0vw 2vw;
    margin-right: 10vw;
  }
  .Content input,
  textarea {
    width: 80vw;
    font-size: 2.8vw !important;
  }

  .Content {
    padding: 4.16vw 1.14vw;
  }
  .Profile {
    margin: 1.45vw 3.01vw 6.7vw 7.01vw;
  }
  .CheckboxContainer {
    width: 81.64vw;
    font-size: 3vw;
  }
  .SubscribeText {
    font-size: 2.45vw;
    color: red;
  }
  .Strength {
    height: 11vw;
    width: 84vw;
    align-self: unset;
    font-size: 3.25vw;
    border-radius: 1vw;
  }
  .MembershipContainer {
    border-radius: 2vw;
    padding: 5vw 4vw;
  }
  .MembershipContainer .Title {
    font-size: 3.27vw;
  }
  .MembershipContainer .Button {
    border-radius: 1vw;
    height: 5vw;
    font-size: 3.25vw;
    padding: 0 3.25vw;
  }
  .MembershipTag {
    grid-column-gap: 15vw;
  }
  .MembershipContainer .Subtitle {
    font-size: 3.27vw;
  }
}
