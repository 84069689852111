.OrderSummary {
  width: 30.09vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 1vw;
  display: flex;
  flex-flow: column;
}

.OrderSummary .Table {
  margin-left: 2.2vw;
  width: 90%;
  margin-top: 0vw;
  margin-bottom: 0;
}

.OrderSummary tr > td:nth-child(2) {
  text-align: center;
}

.CustomBorder {
  margin: 0vw;
}

.TotalPrice {
  padding-right: 1vw;
  font-size: 2.5vw;
  font-weight: 600;
  color: black;
}

.CheckoutButton {
  margin-top: auto;
  display: flex;
  align-self: center;
  margin-bottom: 1vw;
}

.TableInput {
  width: 22.8vw;
}

.TableInput input {
  padding-left: 1.2vw;
  width: 100%;
  border: 2px solid #eeeeee;
  border-radius: 0.5vw;
  height: 3.69vw;
  outline: none;
}

.CouponInput {
  margin: 2vw;
}

.Title {
  margin-left: 2vw;
  font-size: 1.6vw;
  font-weight: 500;
  color: #800e12;
}

.TableName {
  color: #800e12;
  font-size: 1.3vw;
}

.Error {
  font-size: 0.71vw;
  margin: 0.1vw 0.5vw;
  color: red;
}

/* .Discount {
  font-size: 0.81vw;
} */
