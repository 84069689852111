.EventRegistration {
  padding-top: 5.11vw;
  width: 81.9vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border-radius: 1vw;
  padding-bottom: 3vw;
}

.HiddenField {
  width: 1px;
  opacity: -10;
  height: 1px;
  position: absolute;
}

.TopContainer {
  display: flex;
  justify-content: space-around;
}

.Prices {
  width: 48vw;
  height: 43.1vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #eeeeee;
  border-radius: 1vw;
  margin-left: 5.46vw;
}

.Table {
  border-spacing: 0.7vw;
  width: 100%;
  margin: 2.37vw 3.8vw;
  font-size: 1.3vw;
  border-spacing: 0vw 2vw;
}

.Title {
  margin-left: 2vw;
  font-size: 1.6vw;
  font-weight: 500;
  color: #800e12;
}

.OrderSummary {
  width: 21.09vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #eeeeee;
  border-radius: 1vw;
  margin-right: 2.4vw;
  display: flex;
  flex-flow: column;
}

.OrderSummary .Table {
  margin-left: 2.2vw;
  width: 90%;
  margin-top: 0vw;
  margin-bottom: 0;
}

.OrderSummary tr > td:nth-child(2) {
  text-align: center;
}

.CustomBorder {
  margin: 0vw;
}

.TotalPrice {
  padding-right: 1vw;
  font-size: 2.5vw;
  font-weight: 600;
  color: black;
}

.CheckoutButton {
  margin-top: auto;
  display: flex;
  align-self: center;
  margin-bottom: 1vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.BottomContainer {
  width: 65.35vw;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #eeeeee;
  border-radius: 1vw;
  opacity: 1;
  margin-left: 6.46vw;
  margin-top: 4vw;
  padding: 2vw 3vw;
}

.BottomContainer table {
  margin-top: 2vw;
  width: 100%;
  border-spacing: 1vw;
}

.BottomContainer td > div {
  grid-column-gap: 1vw;
  display: flex;
  align-items: center;
}

.TableName {
  font-size: 1.3vw;
  font-weight: 600;
  color: #000000;
}

.TableInput {
  width: 22.8vw;
}

.TableInput input {
  padding-left: 1.2vw;
  width: 100%;
  border: 2px solid #eeeeee;
  border-radius: 0.5vw;
  height: 3.69vw;
  outline: none;
}

.RowNum {
  padding-top: 0.434vw;
}

.FooterButtonsContainer {
  margin-top: 3vw;
  display: flex;
  grid-column-gap: 2vw;
  justify-content: flex-end;
  margin-right: 2vw;
}

.ModalContent {
  background: white;
  width: 69vw;
  padding: 2vw;
}

@media only screen and (max-width: 600px) {
  .TopContainer {
    flex-flow: column;
  }
  .Prices {
    height: unset;
    width: 87%;
    margin-top: 3vw;
  }
  .Title {
    font-size: 4.2vw;
  }
  .Table {
    font-size: 3.2vw;
    width: 93%;
  }
  .OrderSummary {
    margin-right: unset;
    width: auto;
    margin: 3vw 5vw;
    padding: 3vw 0;
  }
  .Title {
    font-size: 4.2vw;
  }
  .TotalPrice {
    font-size: 4.2vw;
  }
  .Title {
    margin-left: 2vw;
  }
  .BottomContainer tr {
    font-size: 3.2vw;
    display: flex;
    flex-flow: column;
  }
  .BottomContainer td > div {
    flex-flow: column;
    align-items: baseline;
  }
  .TableName {
    font-size: 3.2vw;
  }
  .TableInput {
    width: 100%;
  }
  .BottomContainer table {
    width: 93%;
  }
  .BottomContainer .TableInput input {
    height: 9vw;
    border-radius: 2vw;
    padding-left: 4vw;
    margin-top: 2vw;
  }
  .FooterButtonsContainer {
    display: flex;
    flex-flow: column;
  }
  .FooterButtonsContainer > div {
    width: 100%;
  }
  .RowNum {
    display: none;
  }
}
