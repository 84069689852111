.calendar {
  padding: 0.8vw;
  background: #312e2e;
  border-radius: 5px;
  color: #303030;
  display: flex;
  flex-direction: column;
  flex-basis: 250px;
}
.calendar-month_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3vw;
}
.calendar-month_header_wrapper {
  text-align: center;
  margin: 0 auto 25px;
}
.calendar-month_header_title {
  font-weight: 600;
  color: white;
  font-size: 0.7vw;
}
.calendar-header_button {
  cursor: pointer;
  background: transparent;
  border: none;
  position: relative;
  height: 29px;
  width: 29px;
}
.calendar-header_button:focus {
  outline: 0;
}
.calendar-header_button.is-disabled {
  cursor: default;
}
.calendar-header_button.is-next:before,
.calendar-header_button.is-prev:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: fill 0.1s ease-in;
  color: white;
  font-weight: bold;
}
.calendar-header_button.is-disabled .calendar-header_button.is-next,
.calendar-header_button.is-disabled .calendar-header_button.is-prev {
  color: #acb3bb;
}
.calendar-calendar_wrapper.is-shortcut_hover_outrange
  .calendar-header_button.is-next,
.calendar-calendar_wrapper.is-shortcut_hover_outrange
  .calendar-header_button.is-prev {
  border-color: #4a90e2;
  background: #e7f5ff;
}
.calendar-header_button.is-prev:before {
  content: "<";
  color: white;
  font-weight: bold;
}
.calendar-header_button.is-next:after {
  content: ">";
  color: white;
  font-weight: bold;
}
.calendar-days_of_week {
  font-size: 13px;
  display: flex;
  color: #c3c3c3;
  text-align: center;
  margin-bottom: 10px;
}
.calendar-days_of_week_day {
  flex-basis: 15%;
  font-size: 0.6vw;
}
.calendar-week {
  display: flex;
  margin-bottom: 0.5vw;
}
.calendar-week:last-of-type {
  margin-bottom: 0;
}
.calendar-week-day {
  height: 1.8vw;
  width: 1.8vw;
  vertical-align: middle;
  font-size: 0.6vw;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 15%;
  background: transparent;
  color: white;
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
}

.calendar-week-day:first-of-type:hover,
.calendar-week-day:last-of-type:hover {
  border-radius: 50%;
}
.calendar-week-day.is-prev_month,
.calendar-week-day.is-next_month {
  color: rgba(172, 179, 187, 0.85);
}
.calendar-week-day.is-today {
  background: gray;
  font-weight: 700;
  border-radius: 1vw;
}
.calendar-week-day.is-selected {
  background: #e3effc;
  color: #404040;
  position: relative;
  z-index: 1;
  border-radius: 0;
}
.calendar-week-day.is-selected:first-of-type {
  border-radius: 50% 0 0 50%;
}
.calendar-week-day.is-selected:last-of-type {
  border-radius: 0 50% 50% 0;
}
.calendar-week-day.is-selected::before {
  background: #3863a0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  color: #fff;
}
.calendar-calendar_wrapper.is-shortcut_hovered
  .calendar-week-day.is-selected::before {
  background: #4a90e2;
}
.calendar-week-day.is-selected:hover::before {
  content: "";
  box-shadow: inset 0 0 1px 0 #3863a0;
  background: #e3effc;
  color: #404040;
}
.calendar-week-day.is-selected.is-start_selection.calendar-week-day.is-selected:hover::before,
.calendar-week-day.is-selected.is-end_selection.calendar-week-day.is-selected:hover::before {
  background: #3863a0;
}
.calendar-week-day.is-selected.is-start_selection,
.calendar-week-day.is-selected.is-end_selection {
  background: #e3effc;
  color: #fff;
}
.calendar-week-day.is-selected.is-start_selection::before,
.calendar-week-day.is-selected.is-end_selection::before {
  content: "";
}
.calendar-week-day.is-selected.is-start_selection {
  border-radius: 50% 0 0 50%;
}
.calendar-week-day.is-selected.is-start_selection:last-of-type {
  border-radius: 50%;
}
.calendar-week-day.is-selected.is-end_selection {
  border-radius: 0 50% 50% 0;
}
.calendar-week-day.is-selected.is-end_selection.is-start_selection,
.calendar-week-day.is-selected.is-end_selection:first-of-type {
  border-radius: 50%;
}
.calendar-week-day.is-selectable {
  cursor: pointer;
  position: relative;
}
.calendar-week-day.is-selectable:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.calendar-week-day.is-selectable:hover:not(.is-selected) {
  z-index: 1;
  box-shadow: inset 0 0 1px 0 #3863a0;
}
.calendar-week-day.is-not_selectable {
  color: #e4e7ea;
}
.calendar-notice {
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 15px;
  color: #fff;
  background: #ff6c7e;
  max-width: 238px;
}

@media only screen and (max-width: 600px) {
  .calendar-month_header_title {
    font-size: 2.7vw;
  }
  .calendar-days_of_week_day {
    flex-basis: 15%;
    font-size: 2.9vw;
  }
  .calendar-week-day {
    height: 7.5vw;
    width: 10.1vw;
    font-size: 2.6vw;
  }
}
