.Content {
  margin: 8.5vw 9.2vw;
}

.Container p {
  font-weight: 500;
  font-size: 1.3vw;
}

.Container p:first-child,
.Container p:last-child {
  margin-block-start: 0;
}

.Container {
  border-left: 0.4vw solid #7b0002;
  padding-left: 2vw;
}

.GraduateImage {
  height: 28.08vw;
  width: 28.08vw;
}

.WinnersContainer {
  margin-top: 7vw;
}

.CardsContainer {
  width: 73.75vw;
  padding-top: 3.5vw;
  margin: auto;
  grid-row-gap: 5vw;
  display: grid;
  grid-template-columns: auto auto auto;
}

.AccContainer {
  width: 73.75vw;
  padding-top: 3.5vw;
  margin: auto;
}

.Footer {
  margin-top: 9.5vw;
}

.ContainerHead {
  justify-content: space-between;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .Content {
    margin: 8.5vw 7.2vw;
  }
  .ContainerHead {
    justify-content: space-between;
    display: block;
  }
  .Container {
    border-left: none;
    padding-left: 0;
  }
  .Container p {
    color: #312e2e;
    font-size: 3.2vw;
    line-height: 2;
  }
  .ImageContainer {
    margin-left: -8vw;
  }
  .ImageContainer img {
    height: 97.1vw;
    width: 97.1vw;
  }
  .CardsContainer {
    width: 87.75vw;
    grid-template-columns: auto;
  }
  .AccContainer {
    width: 85.1vw;
  }
}
