.VolunteerContent {
  margin: 4.47vw 6.2vw;
}

.VolunteerContent p {
  font-size: 1.3vw;
  width: 80vw;
  letter-spacing: 0px;
  color: #312e2e;
  opacity: 1;
  font-weight: 500;
  margin-top: 2.4vw;
}

.EventButton {
  display: flex;
  align-items: center;
  min-width: 9.5vw;
  padding: 0.2vw 1vw;
  width: auto;
  cursor: unset;
  transition: unset;
}

.EventButton:hover {
  /* background: white radial-gradient(circle, transparent 1%, white 1%) center/15000%; */
}
.EventButton:active {
  background-color: #ebe4e4;
  background-size: 100%;
  transition: unset !important;
}

.EventsContainer {
  display: flex;
  grid-gap: 3%;
  flex-wrap: wrap;
}

.SubHeading {
  font-size: 1.6vw !important;
}

.DoubleSpace {
  margin-top: 4vw !important;
}

.Container {
  margin-top: 7.29vw;
}

.Footer {
  margin: 3vw auto;
}
.FooterTitle {
  font-weight: 600;
  font-size: 1.5vw;
}

@media only screen and (max-width: 600px) {
  .VolunteerContent p {
    font-size: 3.2vw;
    line-height: 1.8;
  }
  .EventButton {
    margin-top: 4vw;
  }
  .SubHeading {
    font-size: 4vw !important;
  }
  .FooterTitle {
    font-weight: 500 !important;
    font-size: 4vw !important;
  }
}
